<template lang="pug">
form
  .columns.is-multiline
    .column.is-9
      input.input.is-medium(
        v-model='url'
        type='text'
        placeholder='cole sua url aqui'
        ref="urlInput"
        :disabled='shortening'
        @focus='selectUrl'
      )
      .error-box(v-if='errorMessage') {{errorMessage}}
    .column.is-3
      v-button.is-fullwidth.is-cta(
        label='Encurtar link'
        label-busy='Encurtando...'
        :busy='shortening'
        @click='shortenUrl'
      )
    .column.is-12
      .field
        .control
          input.input(
            v-model='token'
            type='text'
            placeholder='apelido (opcional)'
            ref="tokenInput"
            :disabled='shortening'
          )
        p.help Use esse campo caso queira definir um apelido para a sua URL. Apenas caracteres de 'a' a 'z', '0' a '9' e '-' são permitidos.
  .url-box(v-if='shortenedUrl')
    #shortened-url.url-box__content {{shortenedUrl}}
    .url-box__controls
      button#copy-url.button.is-primary(type="button" data-init-value="Copiar" data-clipboard-target="#shortened-url") Copiar
</template>

<script>
import axios from 'axios';
import { curry } from 'lodash';

import { authToken } from 'src/utils/dom';
import VButton from 'src/components/Button/';

const props = {
  endpoint: {
    type: String,
    required: true
  }
};

const data = function () {
  return {
    errorMessage: '',
    shortenedUrl: '',
    shortening: false,
    token: '',
    url: ''
  };
};

const computed = {
};

const methods = {
  selectUrl (ev) {
    ev.target.select();
  },
  shortenUrl () {
    const $self = this;
    const payload = { url_translation: { url: this.url, token: this.token } };
    const headers = { 'Accept': 'application/json', 'X-CSRF-Token': authToken() };

    setShortening(this);

    axios.post(this.endpoint, payload, { headers: headers })
      .then(curry(treatSucceededShortening)($self))
      .catch(curry(treatFailedShortening)($self));
  }
};

const setShortening = function (vue) {
  Object.assign(vue.$data, { shortening: true, errorMessage: '', shortenedUrl: '' });
};

const treatFailedShortening = function (vue, { response: { data: { errors: errors } } }) {
  const inputName = Object.keys(errors)[0];
  selectInputWithError(vue, inputName);
  Object.assign(vue.$data, { shortening: false, errorMessage: errors[inputName][0] });
};

const treatSucceededShortening = function (vue, { data: { data: { attributes: attrs } } }) {
  Object.assign(vue.$data, { shortening: false, shortenedUrl: attrs.shortenedUrl });
};

const selectInputWithError = function (vue, inputName) {
  const inputRef = `${inputName}Input`;
  vue.$refs[inputRef].select();
};

export default {
  name: 'url-generator',
  components: {
    VButton
  },
  props,
  data,
  computed,
  methods
}
</script>
