<template lang="pug">
button.button.is-medium(
  type='button'
  :disabled='inactive || busy'
  @click='$emit("click", $event)'
) {{ finalLabel }}
</template>

<script>
const props = {
  inactive: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    required: true
  },
  labelBusy: {
    type: String,
    default: ''
  },
  busy: {
    type: Boolean,
    default: false
  }
};

const computed = {
  finalLabel () {
    return this.busy && this.labelBusy ? this.labelBusy : this.label;
  }
};

export default {
  name: 'v-button',
  props,
  computed
}
</script>
