export const authTokenParam = () => {
  const authTokenKey = cssQuery('meta[name=csrf-param]').getAttribute('content');

  return { [authTokenKey]: authToken() };
};

export const authToken = () => {
  return cssQuery('meta[name=csrf-token]').getAttribute('content');
}

const cssQuery = function (query) {
  return document.querySelector(query);
}
