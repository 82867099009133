import Vue from 'vue';
import Clipboard from 'clipboard/dist/clipboard';

import StatsCard from 'src/components/StatsCard/';
import UrlGenerator from 'src/components/UrlGenerator/';

!function() {
  window.addEventListener('DOMContentLoaded', (ev) => {
    const urlBox = document.querySelector('#url-generator');

    if (!urlBox) return;

    const clipboard = new Clipboard('#copy-url');
    const urlShorteningEndpoint = urlBox.dataset.endpoint;

    clipboard.on('success', (ev) => {
      const btn = ev.trigger;
      const btnValue = btn.getAttribute('data-init-value');

      btn.textContent = 'URL Copiada!';
      setTimeout(() => btn.textContent = btnValue, 5000);
    });

    new Vue({
      el: "#url-generator",
      render: h => h(UrlGenerator, {
        props: { endpoint: urlShorteningEndpoint }
      })
    });

    new Vue({
      el: "#home-stats",
      render: h => h('div', { class: 'home-stats__content' }, [
        createStatCard(h, 'month', 'este mês'),
        createStatCard(h, 'week', 'esta semana'),
        createStatCard(h, 'day', 'hoje')
      ])
    });
  });

  const createStatCard = (h, period, title) =>
    h(StatsCard, { props: { period, title } });
}();
